<div class="data-for-print trading-plan">
  <h1 class="doc-title">Trading Plan | {{ planToPrint?.title }}</h1>
  @if (planToPrint.updatedDate) {
    <p class="doc-last-edited">Last edited: {{ planToPrint.updatedDate | date: 'MMM d, YYYY' }}</p>
  }

  <div
    *ngFor="let section of planToPrint?.sections"
    class="section"
  >
    <h2 class="section-title">{{ section.title }}</h2>
    <div
      [innerHTML]="section.content | sanitizeHtml"
      class="section-content"
    ></div>
  </div>
</div>
